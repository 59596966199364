import React from "react";
import styles from './MapComponent.module.scss';

const MapComponent = () => {
  return(
    <div className={styles.objectsMap}>
      <div className={styles.objectsContent}>
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A02848d3c85cc253fed02a1d63c16c1d99b956577ee0c4975a0c3a4a3ae53aef7&amp;source=constructor" width="100%" height="520" frameborder="0"></iframe>
      </div>
    </div>
  );
}

export default React.memo(MapComponent);